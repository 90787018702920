import React from "react";
import {
  BrowserRouter as Router,
  Route /* Link , Switch */,
} from "react-router-dom";
import Home from "./containers/Home";
import "./App.css";

export default class App extends React.Component {
  render() {
    return (
      <Router basename={window.location.pathname || ""}>
        <React.Fragment>
          <Route exact path="/" component={Home} />
        </React.Fragment>
      </Router>
    );
  }
}
