import React from "react";
import queryString from "query-string";
import "../App.css";
import { Spinner } from "react-activity";

export default class Home extends React.Component {
  render() {
    return <Spinner />;
  }

  componentDidMount() {
    var mobileCheck = {
      all: (function () {
        return navigator.userAgent.match(
          /Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i
        );
      })(),
    };
    var url = this.props.location.search;
    var params = queryString.parse(url);
    var ref = params.ref;
    if (mobileCheck.all !== null) {
      window.onload = function () {
        window.location = "attentionspoilers://response/" + ref;
      };
    } else {
      window.onload = function () {
        window.location = "https://attentionspoilers.com/";
      };
    }
  }
}
